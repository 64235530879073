<template>
	<!-- 分销基本设置 -->
	<div class="el-content">
		<a-tabs v-model:activeKey="active">
			<a-tab-pane key="first" tab="分销设置">
				<div class="tips">
					<p>1、当前分销板块支持只支持二级分销。</p>
					<p>2、当前分销板块支持商城、认养、租赁土地。</p>
					<p>3、分销模式：用户申请成为分销商后、发展分销下线、分销下线在商城中购买商品订单完成之后，该一级分销商和二级分销商可获得相应佣金。</p>
					<p>4、分销佣金将会直接到分销商钱包中，可用于提现。</p>
				</div>
				<a-form :label-col="{span:2}" :wrapper-col="{span:12}">
					<a-form-item label="分销内购">
						<a-radio-group v-model:value="form.is_purchase">
							  <a-radio :value="1">开启</a-radio>
							  <a-radio :value="0">关闭</a-radio>
						</a-radio-group>
						<div class="distribution-set-tip">开启分销内购，分销员购买商品后获得1级收益，上级分销员获得2级收益；</div>
						<div class="distribution-set-tip">关闭分销内购，分销员购买商品没有收益，上级分销员获得1级收益，上上级分销员获得2级收益</div>
					</a-form-item>
					<a-form-item label="锁粉功能">
						<a-radio-group v-model:value="form.is_lock">
							  <a-radio :value="1">开启</a-radio>
							  <a-radio :value="0">关闭</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item label="锁粉周期" v-if="form.is_lock == 1">
						<a-input v-model:value="form.lockday" type="number" min="1" addon-after="天"></a-input>
					</a-form-item>
					
					<a-form-item label="成为分销商途径">
						<a-radio-group v-model:value="form.become_distri">
							  <a-radio :value="1">申请填资料(需审核)</a-radio>
							  <a-radio :value="2">申请填资料(不需审核)</a-radio>
							  <a-radio :value="3">申请不填资料(需审核)</a-radio>
							  <a-radio :value="4">申请不填资料(不需审核)</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item label="成为分销商条件">
						<a-radio-group v-model:value="form.become_distri_condition">
							  <a-radio :value="1">消费一次</a-radio>
							  <a-radio :value="2">无条件</a-radio>
						</a-radio-group>
					</a-form-item>
					
					<a-form-item label="指定消费商品" v-if="form.become_distri_condition == 1">
						<a-select
						    show-search
						    v-model:value="form.goods_id"
						    placeholder="请输入商品名称搜索查找"
						    :default-active-first-option="false"
						    :show-arrow="false"
						    :filter-option="false"
						    :not-found-content="null"
						    @search="searchGoods"
						  >
						    <a-select-option v-for="good in goods" :key="good.id">
								{{ good.goods_name }}
						    </a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="提现方式">
						<a-checkbox-group v-model:value="form.withdrawal_method">
							<a-checkbox value="1">微信</a-checkbox>
							<a-checkbox value="2">支付宝</a-checkbox>
							<a-checkbox value="3">余额</a-checkbox>
						</a-checkbox-group>
					</a-form-item>
					<a-form-item label="最少提现金额">
						<a-input v-model:value="form.min_withdrawal_price"></a-input>
					</a-form-item>
					<a-form-item label="单日提现上限">
						<a-input v-model:value="form.day_withdrawal_price"></a-input>
					</a-form-item>
					<a-form-item label="提现手续费">
						<a-input v-model:value="form.withdrawal_toll" addon-after="%"></a-input>
					</a-form-item>
					
					<a-form-item label="商城订单">
						<a-input v-model:value="form.shop_commission_day" addon-after="天后发放佣金">
							<template #addonBefore>
								<a-select v-model:value="form.commission_time" style="width: 200px">
									<a-select-option :value="1">确认收货后发放</a-select-option>
									<a-select-option :value="2">订单支付完成后发放</a-select-option>
									<a-select-option :value="3">订单发货后发放</a-select-option>
								</a-select>
							</template>
						</a-input>
					</a-form-item>
					<a-form-item label="土地订单">
						<a-input v-model:value="form.land_commission_day" addon-after="天后发放佣金">
							<template #addonBefore>
								<a-select v-model:value="form.commission_time_land" style="width:200px">
									<a-select-option :value="1">确认收货后发放</a-select-option>
									<a-select-option :value="2">订单支付完成后发放</a-select-option>
									<a-select-option :value="3">订单发货后发放</a-select-option>
								</a-select>
							</template>
						</a-input>
					</a-form-item>
					<a-form-item label="认养订单">
						<a-input v-model:value="form.adopt_commission_day" addon-after="天后发放佣金">
							<template #addonBefore>
								<a-select v-model:value="form.commission_time_adopt" style="width: 200px">
									<a-select-option :value="1">确认收货后发放</a-select-option>
									<a-select-option :value="2">订单支付完成后发放</a-select-option>
									<a-select-option :value="3">订单发货后发放</a-select-option>
								</a-select>
							</template>
						</a-input>
					</a-form-item>
					<a-form-item :wrapper-col="{offset:2}">
						<a-button type="primary" @click="submitSaveDisSet">保存</a-button>
					</a-form-item>
				</a-form>
			</a-tab-pane>
			<a-tab-pane key="second" tab="基础设置">
				<com-power-form :form-data="[
					{label:'审核中提示语',type:'text',key:'distribution_check_tag',dataType:'string',
						tips:'用户申请分销商审核中的时候,当用户再次点击进入分销板块时给用户展示的提示语'
					},
					{label:'分享海报提示语',type:'text',key:'distribution_poster_title',dataType:'string',
						tips:'用户在分销中心生成分销海报时,海报上面显示的提示语'
					},
					{label:'分享标题',type:'text',key:'distribution_share_title',dataType:'string',
						tips:'用户分享好友时分享标题'
					},
					{label:'“分销”文字替换文案',type:'text',key:'distribution_txt_replace',dataType:'string',
						tips:'设置此项后,客户端所有的“分销”二字将被替换成设置的文案'
					},
					{label:'申请分销是否展示绑定信息',type:'switch',key:'distribution_open_bind_info',dataType:'string'},
					{label:'分销信息展示',type:'switch',key:'distribution_open_marketing_info',dataType:'string',
						tips:'关闭此项后,客户端商品,认养,租地将不展示分销可获得佣金营销信息'
					},
					{label:'入驻宣传头部图片',type:'image',key:'distribution_banner',dataType:'string'},
					{label:'推广二维码页面背景',type:'image',key:'distribution_qrcode_bg',dataType:'string'},
					{label:'入驻申请协议',type:'editor',key:'distribution_agreement',dataType:'string'},
				]"></com-power-form>
			</a-tab-pane>
		</a-tabs>
	</div>
</template>

<script>
import comPowerForm from '@/components/form/com-power-form.vue'
import { reactive, toRefs } from 'vue'
import distributionModel from '@/api/addons/distribution'
import goodsModel from '@/api/goods'
export default{
	components:{
		comPowerForm
	},
	setup(){
		const state = reactive({
			active:'first',
			form:{
				program_id:0,
				is_purchase : '',
				become_distri_condition : '',
				withdrawal_method : [],
				min_withdrawal_price : '',
				day_withdrawal_price : '',
				withdrawal_toll : '',
				become_distri : '',
				commission_time:1,
				commission_time_adopt:1,
				commission_time_land:1,
				goods_id:'',
				is_lock:0,
				lockday:1,
				shop_commission_day:"",
				land_commission_day:"",
				adopt_commission_day:""
			},
			goods:[]
		})

		distributionModel.getDisConfig(Object.keys(state.form),res=>{
			if( res ){
				state.form = res
				if( !state.form.goods_id ) state.form.goods_id = ''
				if( typeof state.form.withdrawal_method == 'string'){
					state.form.withdrawal_method = state.form.withdrawal_method.split(',')
				}
				if( res.goods ){
					state.goods.push(res.goods)
				}
			}
		})

		function searchGoods(e){
			goodsModel.getGoodsList(1,999,{key:e},res=>state.goods = res.list)
		}

		const submitSaveDisSet = ()=>{
			console.log('111',state.form);
			
			distributionModel.saveDisConfig(JSON.parse(JSON.stringify(state.form)))
		}



		return{
			...toRefs(state),
			submitSaveDisSet,
			searchGoods
		}
	}
}
</script>

<style lang="scss">
	.distribution-set-tip{
		color: #999;
		font-size: 12px;
		height: 18px;
		line-height: 18px;
	}
</style>
